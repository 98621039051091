import ReactDOM from 'react-dom/client';
import App from './App';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store.js'
import { PersistGate } from 'redux-persist/integration/react';

const queryClient = new QueryClient({
  defaultOptions: {
    retry: 5,
    refetchOnReconnect: true,
    staleTime: 5 * 60 * 1000, // 5 minute
    refetchOnWindowFocus: true,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  },
});

// Register Firebase Messaging Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(function (registration) {
      // Ensure the service worker is active before subscribing to push notifications
      return navigator.serviceWorker.ready;
    })
    .then(function (registration) {
      console.log('Service Worker active and ready for push notifications');
      // Now it's safe to subscribe to PushManager here
    })
    .catch(function (error) {
      console.log('Service Worker registration or readiness failed: ', error);
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);