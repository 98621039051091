import { TYPES } from "../types";

const INITIAL_STATE = {
  clientStrategies: {
    augusta: {
      generalData: {},
      propertyDetail:{}
    },
    hyc: {
      generalData: {},
    },
  },
};
const {
  GENERAL_DATA,
  PROPERTY_DETAIL
} = TYPES;

export default (state = INITIAL_STATE, action) => {
  const { type, payload, strategy } = action;
  
  switch (type) {
    case GENERAL_DATA:
      return {
        ...state,
        clientStrategies: {
          ...state.clientStrategies,
          [strategy]: {
            ...state.clientStrategies[strategy],
            generalData: {
              ...state.clientStrategies[strategy].generalData,
              ...payload,
            },
          },
        },
      }
      case PROPERTY_DETAIL:
        return {
          ...state,
        clientStrategies: {
          ...state.clientStrategies,
          [strategy]: {
            ...state.clientStrategies[strategy],
            propertyDetail: {
              ...state.clientStrategies[strategy].propertyDetail,
              ...payload,
            },
          },
        },
        };
    default:
      return state;
  }
};