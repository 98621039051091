
export const Colors = {
    DefaultBlackColor:`#110c1d`,
    Blue:`var(--Blue)`,
    Theme:`var(--primaryColor)`,
    BgTheme:`var(--CharcoalBlue)`,
    White:`var(--White)`,
    Gray:`var(--Gray)`,
    PrimaryColor:`var(--primaryColor)`,
    Orange:`var(--Orange)`,
    Red:`var(--Red)`,
    SeaBlue:`var(--SeaBlue)`,
    Black:`var(--BgEbony)`,
    Pink:`var(--Pink)`,
    Green:`var(--Green)`,
    Yellow:`var(--Yellow)`,
    Magenta:`var(--Magenta)`,
    LightGray:`var(--LightGray)`,
    DarkBlue:`var(--DarkBlue)`,
    DarkGray:`var(--DarkGray)`,
    LightGreen:`var(--LightGreen)`,
}