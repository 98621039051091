import React from 'react';
import { StaticImage } from './Images';
import Tags from '../components/Tags';
import CustomImage from 'components/CustomImage';

const SuspenseLoader = () => {
    return (
        <div style={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
            <div className='text-center'>
                <CustomImage preview={false} src={StaticImage.logo.blueLogo} height={90} width={250} />
                <Tags title={`Loading...`} className={`text-Blue font-normal`} tag='h2' />
            </div>
        </div>
    )
}

export default SuspenseLoader