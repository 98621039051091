import { TiMinus } from "react-icons/ti";
import { SlPeople } from "react-icons/sl";
import { CgCloseR } from "react-icons/cg";
import { GiTakeMyMoney } from "react-icons/gi";
import { LuCalendarClock } from "react-icons/lu";
import { SiCalendly, SiGoogleadsense } from "react-icons/si";
import { PiUsersThreeFill } from "react-icons/pi";
import { BsCurrencyDollar } from "react-icons/bs";
import { ImCheckboxUnchecked } from "react-icons/im";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import { FcCancel, FcGoogle, FcSimCardChip } from "react-icons/fc";
import { RiCloseFill, RiLogoutBoxLine, RiMenu2Fill, RiSendPlaneFill } from "react-icons/ri";
import { FaAddressCard, FaArrowsAltH, FaCalendarAlt, FaChessBoard, FaEye, FaEyeSlash, FaFilePdf, FaRegBell, FaUserCircle } from "react-icons/fa";
import { IoAddCircle, IoCardOutline, IoInformationCircleSharp, IoLocationSharp, IoSearchOutline, IoCloseCircle } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp, IoIosCloseCircle, IoIosStar, IoIosStarOutline, IoMdArrowDropdown, IoMdCheckbox, IoMdMore, IoMdStarHalf } from "react-icons/io";
import { MdAccessTime, MdArrowDropDown, MdDelete, MdEmail, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineAdd, MdOutlineSettingsSuggest, MdLocationPin, MdSquareFoot, MdPendingActions, MdModeEditOutline, MdPrivateConnectivity, MdKeyboardDoubleArrowRight, MdPlayCircleFilled } from "react-icons/md";

export const ReactIcons = {
    EyeIcon: ({ style }) => <FaEye style={{ ...style }} />,
    TiMinus: ({ style }) => <TiMinus style={{ ...style }} />,
    MailIcon: ({ style }) => <MdEmail style={{ ...style }} />,
    Dot3Icon: ({ style }) => <IoMdMore style={{ ...style }} />,
    PdfIcon: ({ style }) => <FaFilePdf style={{ ...style }} />,
    StarIcon: ({ style }) => <IoIosStar style={{ ...style }} />,
    DeleteIcon: ({ style }) => <MdDelete style={{ ...style }} />,
    CancelIcon: ({ style }) => <FcCancel style={{ ...style }} />,
    PeopleIcon: ({ style }) => <SlPeople style={{ ...style }} />,
    GoogleIcon: ({ style }) => <FcGoogle style={{ ...style }} />,
    AddIcon: ({ style }) => <MdOutlineAdd style={{ ...style }} />,
    MenuIcon: ({ style }) => <RiMenu2Fill style={{ ...style }} />,
    TimeIcon: ({ style }) => <MdAccessTime style={{ ...style }} />,
    CloseIcon: ({ style }) => <RiCloseFill style={{ ...style }} />,
    GageIcon: ({ style }) => <MdSquareFoot style={{ ...style }} />,
    UserIcon: ({ style }) => <FaUserCircle style={{ ...style }} />,
    EyeOffIcon: ({ style }) => <FaEyeSlash style={{ ...style }} />,
    ChipIcon: ({ style }) => <FcSimCardChip style={{ ...style }} />,
    CardIcon: ({ style }) => <IoCardOutline style={{ ...style }} />,
    CalendlyIcon: ({ style }) => <SiCalendly style={{ ...style }} />,
    IoAddCircle: ({ style }) => <IoAddCircle style={{ ...style }} />,
    CheckedIcon: ({ style }) => <IoMdCheckbox style={{ ...style }} />,
    MapPinIcon: ({ style }) => <MdLocationPin style={{ ...style }} />,
    ArrowUpIcon: ({ style }) => <IoIosArrowUp style={{ ...style }} />,
    StrategyIcon: ({ style }) => <FaChessBoard style={{ ...style }} />,
    AddressIcon: ({ style }) => <FaAddressCard style={{ ...style }} />,
    LeadsIcon: ({ style }) => <SiGoogleadsense style={{ ...style }} />,
    HalfStarIcon: ({ style }) => <IoMdStarHalf style={{ ...style }} />,
    connectArrow: ({ style }) => <FaArrowsAltH style={{ ...style }} />,
    PaymentIcon: ({ style }) => <GiTakeMyMoney style={{ ...style }} />,
    LogoutIcon: ({ style }) => <RiLogoutBoxLine style={{ ...style }} />,
    EditIcon: ({ style }) => <MdModeEditOutline style={{ ...style }} />,
    CalendarIcon: ({ style }) => <FaCalendarAlt style={{ ...style }} />,
    SearchIcon: ({ style }) => <IoSearchOutline style={{ ...style }} />,
    UsersIcon: ({ style }) => <PiUsersThreeFill style={{ ...style }} />,
    DollarIcon: ({ style }) => <BsCurrencyDollar style={{ ...style }} />,
    ArrowDownIcon: ({ style }) => <IoIosArrowDown style={{ ...style }} />,
    LocationIcon: ({ style }) => <IoLocationSharp style={{ ...style }} />,
    PendingIcon: ({ style }) => <MdPendingActions style={{ ...style }} />,
    CloseCircleIcon: ({ style }) => <IoCloseCircle style={{ ...style }} />,
    SendPlaneIcon: ({ style }) => <RiSendPlaneFill style={{ ...style }} />,
    LuCalendarClock: ({ style }) => <LuCalendarClock style={{ ...style }} />,
    OfficeIcon: ({ style }) => <HiMiniBuildingOffice2 style={{ ...style }} />,
    OutlineStarIcon: ({ style }) => <IoIosStarOutline style={{ ...style }} />,
    PlayCircleIcon: ({ style }) => <MdPlayCircleFilled style={{ ...style }} />,
    ConnectIcon: ({ style }) => <MdPrivateConnectivity style={{ ...style }} />,
    UnCheckedIcon: ({ style }) => <ImCheckboxUnchecked style={{ ...style }} />,
    ArrowDropDownIcon: ({ style }) => <MdArrowDropDown style={{ ...style }} />,
    ArrowLeftIcon: ({ style }) => <MdKeyboardArrowLeft style={{ ...style }} />,
    SetUpIcon: ({ style }) => <MdOutlineSettingsSuggest style={{ ...style }} />,
    CircleCloseIcon: ({ style }) => <IoIosCloseCircle style={{ ...style }} />,
    ArrowRightIcon: ({ style }) => <MdKeyboardArrowRight style={{ ...style }} />,
    IoMdArrowDropdown: ({ style }) => <IoMdArrowDropdown style={{ ...style }} />,
    InfoCircleIcon: ({ style }) => <IoInformationCircleSharp style={{ ...style }} />,
    PaymentSuccessIcon: ({ style }) => <TbRosetteDiscountCheckFilled style={{ ...style }} />,
    DoubleArrowRightIcon: ({ style }) => <MdKeyboardDoubleArrowRight style={{ ...style }} />,
    BellIcon: ({ style , className}) => <FaRegBell style={{ ...style }} className={className} />,
    CloseBorderIcon: ({ style, className }) => <CgCloseR style={{ ...style }} className={className} />,

}