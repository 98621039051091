import PropTypes from 'prop-types';

const Tags = ({ children, title, tag: Tag = 'h1', className = '', style, role }) => {
  return (
    <Tag className={`${className}`} style={style} role={role} onKeyDown={(event) => { if (event.key === 'Enter'); }} >{children || title}</Tag>
  )
}

Tags.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string, // 'tag' is optional and must be a string
  role: PropTypes.string, // 'role' is optional and must be a string
  className: PropTypes.string, // 'className' is optional and must be a string
  style: PropTypes.object, // 'style' is optional and must be an object
};

// Export the default Tags component with its propTypes
export default Tags;