export const TYPES = {
    LOGIN: 'LOGIN',
    JWT_TOKEN: 'JWT_TOKEN',
    LOGIN_TYPE: 'LOGIN_TYPE',
    APP_SETTING: 'APP_SETTING',
    USER_DETAIL: "USER_DETAIL",
    FCM_TOKEN:'FCM_TOKEN',
    CLIENT_TYPE: "CLIENT_TYPE",
    GENERAL_DATA:'GENERAL_DATA',
    MODIFIED_DATA: 'MODIFIED_DATA',
    SELECTED_RULES: 'SELECTED_RULES',
    COMMON_CONTENT: 'COMMON_CONTENT',
    PROPERTY_DETAIL:'PROPERTY_DETAIL',
    CALCULATOR_DATA: 'CALCULATOR_DATA',
    CALCULATOR_RULES: "CALCULATOR_RULES",
    INTERNAL_HEADER_DATA: 'INTERNAL_HEADER_DATA',
}