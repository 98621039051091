import { Suspense, lazy, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import SuspenseLoader from "./utils/SuspenseLoader";
import MyToast from "./utils/MyToast";
import { requestNotificationToken } from "firebase.config";

const App = () => {
  const AppRouter = lazy(() => import("./routes/MainRoute.js"));

  useEffect(() => {
    // Request the notification token (handles both Safari and other browsers)
    requestNotificationToken();

    const handleOnline = () => {
      MyToast("s", "Network connection restored!", "top-center");
      window.location.reload();
    };

    const handleOffline = () => {
      MyToast("w", "Network connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    document.addEventListener('DOMContentLoaded', () => {
      MyToast("s", "Page loaded successfully!", "top-center");
    });

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <ErrorBoundary>
        <AppRouter />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
