import { TYPES } from "../types";

const INITIAL_STATE = {
  jwtToken: null,
  fcmToken: null,
  loginType: null,
  clientType: null,
  userDetail: null,
  commonData: null,
  internalHeaderData: null,
  modifiedData: {},
  calculatorData: {},
  calculatorRules: [],

  appSetting: {
    Color: null,
    Address: null,
    AppSetup: null,
    profilePic: null,
  },
  selectedRules: {
    mergedData: [],
    addonData: [],
    personalBuildData: [],
    businessBuildData: []
  }

};
const {
  JWT_TOKEN,
  FCM_TOKEN,
  LOGIN_TYPE,
  USER_DETAIL,
  CLIENT_TYPE,
  APP_SETTING,
  MODIFIED_DATA,
  COMMON_CONTENT,
  SELECTED_RULES,
  CALCULATOR_DATA,
  CALCULATOR_RULES,
  INTERNAL_HEADER_DATA,
} = TYPES;

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case JWT_TOKEN:
      return {
        ...state,
        jwtToken: payload,
      };
    case FCM_TOKEN:
      return {
        ...state,
        fcmToken: payload,
      };
    case COMMON_CONTENT:
      return {
        ...state,
        commonData: payload,
      };
    case CALCULATOR_DATA:
      return {
        ...state,
        calculatorData: payload,
      };
    case INTERNAL_HEADER_DATA:
      return {
        ...state,
        internalHeaderData: payload,
      };
    case CALCULATOR_RULES:
      console.log('CALCULATOR_RULES payload?.payload', payload?.payload)
      return {
        ...state,
        calculatorRules: payload?.payload,
      };
    case SELECTED_RULES: {
      const modifiedRules = {
        ...state.selectedRules,
        addonData: action.payload.payload.addonData,
        mergedData: action.payload.payload.mergedData,
        businessBuildData: action.payload.payload.businessBuildData,
        personalBuildData: action.payload.payload.personalBuildData,
      };
      return {
        ...state,
        selectedRules: modifiedRules
      };
    }
    case MODIFIED_DATA:
      return {
        ...state,
        modifiedData: payload,
      };
    case LOGIN_TYPE:
      return {
        ...state,
        loginType: payload?.payload,
      };
    case APP_SETTING:
      return {
        ...state,  // Keep the rest of the state
        appSetting: {
          ...state?.appSetting,  // Ensure you maintain the previous `appSetting`
          Color: payload?.payload?.Color,
          Address: payload?.payload?.Address,
          AppSetup: payload?.payload?.AppSetup,
          profilePic: payload?.payload?.profilePic,
        },
      };
    case CLIENT_TYPE:
      return {
        ...state,
        // clientType: payload?.payload,
        clientType: {
          ...state.clientType, // Spread the existing clientType object
          ...payload?.payload, // Merge the new data
        },
      };
    case USER_DETAIL:
      if (payload) {
        return {
          ...state,
          userDetail: payload?.payload,
        };
      }
      else {
        return state;
      }
    default:
      return state;
  }
};