import { Colors } from "./Colors";

export const styles = {
    Active: {
        backgroundColor: Colors.Theme,
        color: Colors.White,
        border: `2px solid ${Colors.Theme}`,
        borderRadius: '0.8rem',
        fontSize: 18,
        minHeight: '3.1rem'
    },
    InActive: {
        backgroundColor: 'transparent',
        color: 'gray',
        margin: '0 8px',
        opacity: 0.5,
        fontSize: 18,
        borderRadius: '0.8rem',
        minHeight: '3.1rem',
        border: `2px solid ${Colors.Theme}`
    },
    IconStyle:{
        color: Colors.PrimaryColor,
        fontSize: 20,
    },
    IconStyleWhite:{
        color: Colors.White,
        fontSize: 20,
    },
    IconStyleWhiteXl:{
        color: Colors.White,
        fontSize: 30,
    }
}