// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getMessaging, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyDo2bm1LMUynLwOIxqfJ8Li5_sd7c7qANg",
//     authDomain: "galileo-dd570.firebaseapp.com",
//     projectId: "galileo-dd570",
//     storageBucket: "galileo-dd570.appspot.com",
//     messagingSenderId: "449691769966",
//     appId: "1:449691769966:web:eabe843e498721d0db5a7f",
//     measurementId: "G-QGV345BPZQ"
// };

// const app = initializeApp(firebaseConfig);

// // Initialize messaging based on browser vendor
// let messaging = null;
// if (navigator.vendor !== 'Apple Computer, Inc.') {
//     messaging = getMessaging(app);  // Correct usage of getMessaging
// } else {
//     console.warn("Push notifications are not supported on Safari at the moment.");
// }

// /* RESPONSIBLE FOR FIRING NOTIFICATION */
// export const onMessageListener = (navRec) => {
//     return new Promise((resolve, reject) => {
//         if (messaging) {
//             onMessage(messaging, (payload) => {
//                 navRec(payload);
//                 resolve(payload);
//             });
//         } else {
//             reject('Firebase Messaging is not supported in this browser.');
//         }
//     });
// }

// export default messaging;

// export const auth = getAuth(app);
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDo2bm1LMUynLwOIxqfJ8Li5_sd7c7qANg",
    authDomain: "galileo-dd570.firebaseapp.com",
    projectId: "galileo-dd570",
    storageBucket: "galileo-dd570.appspot.com",
    messagingSenderId: "449691769966",
    appId: "1:449691769966:web:eabe843e498721d0db5a7f",
    measurementId: "G-QGV345BPZQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

let messaging = null;
// firebase.messaging.isSupported() ----------------> Check for messaging
// Function to request the FCM token based on the browser
export const requestNotificationToken = async () => {
    // Check if the browser is Safari
    if (navigator.vendor === 'Apple Computer, Inc.' && window.safari) {
        console.log('Safari detected, requesting Safari Push Notification permission');
        return requestSafariToken();
    } else {
        // For Chrome, Firefox, etc.
        console.log('Non-Safari browser detected, requesting FCM token');
        return requestFCMToken();
    }
};

// Function to request Safari push token
const requestSafariToken = async () => {
    return new Promise((resolve, reject) => {
        const permissionData = window.safari.pushNotification.permission("web.com.yourdomain.appname"); // Your Safari Web ID

        if (permissionData.permission === "default") {
            // Request permission from Safari
            window.safari.pushNotification.requestPermission(
                "https://your-domain.com", // Your web service URL
                "web.com.yourdomain.appname", // Your Safari Web ID
                {}, // Additional data (optional)
                (permission) => {
                    if (permission.permission === "granted") {
                        const deviceToken = permission.deviceToken;
                        console.log("Safari Device Token:", deviceToken);
                        resolve(deviceToken); // Return the device token
                    } else {
                        console.error("Safari Push Notifications permission denied.");
                        reject(new Error("Permission denied for Safari push notifications."));
                    }
                }
            );
        } else if (permissionData.permission === "granted") {
            console.log("Safari Push Notifications permission already granted.");
            resolve(permissionData.deviceToken);
        } else {
            console.error("Safari Push Notifications are denied.");
            reject(new Error("Safari Push Notifications are denied."));
        }
    });
};

// Function to request FCM token for non-Safari browsers
const requestFCMToken = async () => {
    if (!messaging) {
        messaging = getMessaging(app);
    }

    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
        if (currentToken) {
            return currentToken; // Return the FCM token
        } else {
            throw new Error('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.error('An error occurred while retrieving the FCM token:', err);
        throw err;
    }
};

// Listen to incoming messages (for foreground notifications)
export const onMessageListener = (notificationData) => {
    return new Promise((resolve, reject) => {
        if (messaging) {
            onMessage(messaging, (payload) => {
                notificationData(payload);
                resolve(payload);
            });
        } else {
            reject(new Error('Firebase Messaging is not supported in this browser.'));
        }
    });
};

export default messaging;