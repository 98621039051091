import React, { useRef } from 'react';
import { Image } from 'antd';
import PropTypes from 'prop-types';

const CustomImage = ({
    src,
    alt,
    height,
    width,
    onClick,
    onLoad,
    onError,
    className = '',
    style,
    preview = false,
    draggable = false,
    ...restProps
}) => {
    const imgRef = useRef(null);

    return (
        <Image
            {...restProps}
            ref={imgRef}
            height={height}
            width={width}
            src={src}
            alt={alt}
            preview={preview}
            draggable={draggable}
            onClick={onClick}
            onLoad={onLoad}
            onError={onError}
            className={`custom-image ${className}`}
            style={style}
        />
    );
};

CustomImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    preview: PropTypes.bool,
    draggable: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default CustomImage;