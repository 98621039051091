import chat from "../../src/assets/images/chat.png";
import BlueLogo from '../assets/images/BlueLogo.png';
import WhiteLogo from '../assets/images/fullLogo.png';
import money from "../../src/assets/images/money.gif";
import noData from "../../src/assets/images/noData.jpg";
import growth from "../../src/assets/images/growth.png";
import superman from "../../src/assets/images/superman.png";
import ShortBlueLogo from '../assets/images/galileoLogo.png';
import stripeLogo from "../../src/assets/images/StripeLogo.png";
import ShortWhiteLogo from '../assets/images/galileoLogo White.png';
import CloudUpload from '../assets/images/cloudUpload.png';
import UserUpload from '../assets/images/userUpload.png';
import Completed from '../assets/images/completed.png';
import RightArrow from '../assets/images/RightArrow.png';
import addMedia from '../assets/images/addMedia.png';
import GalileoTaxAppGif from '../assets/images/GalileoTaxApp.gif';

export const StaticImage = {
    logo: {
        blueLogo: BlueLogo,
        whiteLogo: WhiteLogo,
        shortBlueLogo: ShortBlueLogo,
        shortWhiteLogo: ShortWhiteLogo,
    },
    suspenseMedia: "/images/suspense-media.jpg",
    noData:noData,
    stripeLogo:stripeLogo,
    superman,
    money,
    growth,
    chat,
    CloudUpload,
    UserUpload,
    Completed,
    RightArrow,
    GalileoTaxAppGif,
    addMedia,
}